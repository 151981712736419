<template>
  <div>
    <v-card outlined>

      <v-toolbar dense dark flat color="primary">
        <v-toolbar-title>
          <span>Calificaciones</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
          <v-btn
            v-if="!vistaSeguimiento && permisoEscritura && !transversal"
            color="white"
            light
            small
            :class="{ 'disable-btn': botonCalificarGeneral || loading }"
            @click="agregarCalificacionGeneral = true"
            >Calificar todos
            <v-icon small right>mdi-account-multiple-check</v-icon>
          </v-btn>
      </v-toolbar>

      <v-skeleton-loader
      v-if="loading"
      type="list-item-avatar@5"
      style="margin: 20px"
      ></v-skeleton-loader>

      <template v-else>

      <tablaAlumnos
        v-if="tipoEntrega == 'individual'"
        :idCurso="idCurso"
        :idActividad="idActividad"
        :disponible="disponible"
        :tipoActividad="tipoActividad"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :alumnos="alumnos"
        :calificaciones="calificaciones"
        :intentos="intentos"
        :documentos="documentos"
        :permisoEscritura="permisoEscritura"
        :transversal="transversal"
        :reenvioXAlumno="reenvioXAlumno"
        @calificacionAgregada="calificacionAgregada"
        @calificacionModificada="calificacionModificada"
        @calificacionModificadaArchivo="calificacionModificadaArchivo"
      />
      <tablaEquipos
        v-else
        :idCurso="idCurso"
        :idActividad="idActividad"
        :idModulo="idModulo"
        :disponible="disponible"
        :tipoActividad="tipoActividad"
        :instrumento="instrumento"
        :tipoInstrumento="tipoInstrumento"
        :entregaActividad="entrega"
        :alumnos="alumnos"
        :equipos="equipos"
        :calificaciones="calificaciones"
        :documentos="documentos"
        :permisoEscritura="permisoEscritura"
        :transversal="transversal"
        :reenvioXAlumno="reenvioXAlumno"
        @equipoAgregado="equipoAgregado"
        @equipoModificado="equipoModificado"
        @equiposImportados="equiposImportados"
        @equipoActividadEliminado="equipoActividadEliminado"
        @calificacionAgregada="calificacionAgregada"
        @calificacionModificada="calificacionModificada"
        @calificacionModificadaArchivo="calificacionModificadaArchivo"
      />
      </template>

    </v-card>
    <!-- Componente para agregar calificación general -->
    <agregarCalificacionGeneralComponent
      v-if="agregarCalificacionGeneral"
      :mostrar="agregarCalificacionGeneral"
      :idCurso="idCurso"
      :idActividad="idActividad"
      :instrumento="instrumento"
      :tipoInstrumento="tipoInstrumento"
      :tipoActividad="tipoActividad"
      :permisoEscritura="permisoEscritura"
      @cancelar="agregarCalificacionGeneral = false"
      @calificacionesAgregadas="calificacionesAgregadas"
    />
  </div>

</template>

<script>
import { mapGetters } from "vuex";
import { listadoEntregasActividadService } from "./entregas.service";
import { ordenarObjetosAsc } from "../../../../helpers/ordenador";
import tablaAlumnos from "./tablaAlumnos.vue";
import tablaEquipos from "./tablaEquipos.vue";
import agregarCalificacionGeneralComponent from "./calificacionEntregas/calificacionGeneral.vue";

export default {
  name: "listadoEntregas",
  props: {
    actualizarTipo: Number,
    actualizarEntregas: Number,
    actualizarActividad: String,
    transversal: { type: Object, default: null },
  },
  components: {
    tablaAlumnos,
    tablaEquipos,
    agregarCalificacionGeneralComponent,
  },
  watch: {
    sessionToken() {
      this.cargarListadoEntregas();
    },
    "$route.params.idActividad"(value) {
      this.idActividad = value;
      this.cargarListadoEntregas();
    },
    actualizarTipo() {
      this.cargarListadoEntregas();
    },
    actualizarEntregas() {
      this.cargarListadoEntregas();
    },
    actualizarActividad() {
      this.cargarListadoEntregas();
    },
  },
  computed: {
    ...mapGetters(["sessionToken", "vistaSeguimiento"]),
    tipoEntrega() {
      return this.entrega == 1 ? "individual" : "equipo";
    },
    botonCalificarGeneral() {
      if (this.entrega === 1) if (this.alumnos.length === 0) return true;
      if (this.entrega === 2) if (this.equipos.length === 0) return true;
      if (this.tipoActividad === 2)
        if (this.documentos.length === 0) return true;
      return false;
    },
  },
  mounted() {
    this.idCurso = this.$route.params.idCurso;
    this.idActividad = this.$route.params.idActividad;
    if (this.sessionToken) this.cargarListadoEntregas();
  },
  data() {
    return {
      loading: false,
      idCurso: null,
      idActividad: null,
      idModulo: null,
      tipoActividad: 1,
      instrumento: null,
      tipoInstrumento: null,
      disponible: null,
      entrega: 1,
      alumnos: [],
      equipos: [],
      calificaciones: [],
      intentos: [],
      documentos: [],
      permisoEscritura: false,
      agregarCalificacionGeneral: false,
      reenvioXAlumno: [],
    };
  },
  methods: {
    async cargarListadoEntregas() {
      try {
        this.loading = true;
        const serverResponse = await listadoEntregasActividadService(
          this.idCurso,
          this.idActividad
        );
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.idModulo = serverResponse.idModulo;
          if(this.transversal) this.entrega = this.transversal.entrega
          else this.entrega = serverResponse.entrega;
          this.disponible = serverResponse.disponible;
          this.tipoActividad = serverResponse.tipoActividad;
          this.instrumento = serverResponse.instrumento;
          this.tipoInstrumento = serverResponse.tipoInstrumento;
          this.calificaciones = serverResponse.calificaciones;
          this.intentos = serverResponse.intentos || [];
          this.permisoEscritura = serverResponse.permisoEscritura;
          this.documentos = serverResponse.documentos;
          this.reenvioXAlumno = serverResponse.reenvioXAlumno;
          if (serverResponse.alumnos.length > 0)
            this.alumnos = ordenarObjetosAsc(serverResponse.alumnos, "nombreCompleto");
          this.equipos = serverResponse.equipos;
        }
      } catch (error) {
        this.extemporaneo = !this.extemporaneo;
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    calificacionAgregada(calificacion) {
      this.calificaciones.push(calificacion);
    },
    calificacionModificada(calificacion) {
      const indexCalificacion = this.calificaciones.findIndex(
        (x) => x._id == calificacion._id
      );
      this.calificaciones[indexCalificacion].calificacion =
        calificacion.calificacion;
      this.calificaciones[indexCalificacion].observaciones =
        calificacion.observaciones;
      this.calificaciones[indexCalificacion].indicadores =
        calificacion.indicadores;
    },
    calificacionModificadaArchivo(calificacion) {
      const indexDocumento = this.documentos.findIndex((x) => x._id == calificacion._id);

      if(this.documentos[indexDocumento].revision.calificacion != calificacion.revision.calificacion) {
        if (this.reenvioXAlumno) {
          let index = this.reenvioXAlumno.findIndex((x) => x.usuario == calificacion.usuario);
          this.reenvioXAlumno[index].existeNuevoArchivo = false;
        }
      }

      this.documentos[indexDocumento].revision = calificacion.revision;
    },
    equipoAgregado(equipo) {
      this.equipos.push(equipo);
      this.$emit("equipoAgregado", equipo);
    },
    equipoModificado(equipo) {
      const index = this.equipos.findIndex((x) => x._id == equipo._id);
      if (index >= 0) {
        this.equipos[index].nombre = equipo.nombre;
        this.equipos[index].integrantes = equipo.integrantes;
      }
    },
    equiposImportados(equipos) {
      this.equipos = equipos;
    },
    equipoActividadEliminado(idEquipo) {
      const index = this.equipos.findIndex((x) => x._id == idEquipo);
      if (index >= 0) this.equipos.splice(index, 1);
      this.$emit("equipoActividadEliminado", idEquipo);
    },
    calificacionesAgregadas(emitData) {

      this.agregarCalificacionGeneral = false;
      if (this.tipoActividad === 1) {
        emitData.calificaciones.forEach((calificacion) => {
          this.calificaciones.push(calificacion);
        });
      }

      if (this.tipoActividad === 2) {
        emitData.calificaciones.forEach((calificacion) => {
          this.documentos.forEach((documento) => {
            if (calificacion._id === documento._id) {
              documento.revision = calificacion.revision;
            }
          });
        });
      }

      if (this.tipoActividad === 4) {
        this.calificaciones = emitData.calificaciones;
        emitData.intentos.forEach((intento) => {
          this.intentos.push(intento)
        });
      }
    },
  },
};
</script>

<style>
.disable-btn {
  pointer-events: none;
  opacity: 0.5;
}
</style>